import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Head from "../components/Head"
import services from "../../data/services"
import styled from "styled-components"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"
import palette from "../components/GlobalStyle/globals/palette"
import bgImg from "../../assets/images/pic10.jpg"
import bgImgHeader from "../../assets/images/pic12.jpg"
import Link from "../components/Link"

const ServicesPage = props => {
  const [service, setService] = useState({})
  const { location } = props

  useEffect(() => {
    const queryString = location.search
    const urlParams = new URLSearchParams(queryString)
    const product = urlParams.get("val")
    if (product) {
      setService(services[product])
    } else {
      setService(services[0])
    }
  }, [location])

  const Root = {
    Wrapper: styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      min-height: 90vh;

      @media (max-width: ${breakpoints.large}px) {
        justify-content: center;
      }
      @media (min-width: ${breakpoints.large}px) {
        font-size: 1.1rem;
      }
    `,
    SideNavBar: styled.div`
      flex: 0 0 30%;
      @media (min-width: ${breakpoints.large}px) {
        align-self: flex-start;
        margin-top: 8%;
      }

      @media (max-width: ${breakpoints.large}px) {
        flex: 0 0 80%;
        width: 100%;
        order: 1;
        margin: 20px 0;
      }

      @media (max-width: ${breakpoints.medium}px) {
        flex: 0 0 90%;
      }

      .selected {
        background-image: linear-gradient(
          135deg,
          ${palette.primary.main} 0%,
          #5c83e3 100%
        );

        a {
          color: #fff;
        }
      }

      ul {
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
        overflow: hidden;

        @media (max-width: ${breakpoints.large}px) {
          margin-bottom: 10px;
        }

        a {
          padding-right: 30px;
          display: block;
          border-bottom: 1px solid #ecf7ff;
          height: 59px;
          line-height: 59px;
          font-size: 0.9rem;
          color: #3b566e;
          letter-spacing: 0.88px;
          transition: all 0.3s ease 0s;
          text-decoration: none;
          position: relative;

          :hover {
            padding-right: 40px;
          }

          ::before {
            content: "";
            height: 6px;
            width: 6px;
            border-bottom: 1px solid currentColor;
            border-left: 1px solid currentColor;
            transform: translateY(-50%) rotate(45deg);
            display: block;
            position: absolute;
            top: 50%;
            right: 10px;
          }
        }
      }
    `,

    ServiceData: styled.div`
      align-self: flex-start;
      padding: 50px;
      flex: 0 0 60%;
      display: felx;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      p {
        padding: 20px 0;
        min-height: 150px;
        width: 100%;
      }

      img {
        width: 100%;
        border-radius: 10px;
        margin: 10px 0;
      }

      @media (max-width: ${breakpoints.large}px) {
        padding: 20px 0;
        text-align: center;
        justify-content: center;
        flex: 0 0 80%;

        p {
          padding: 0 0 10px 0;
        }
      }
      @media (max-width: ${breakpoints.medium}px) {
        flex: 0 0 90%;
      }
    `,
  }

  const setTheService = e => {
    setService(services[e.currentTarget.id])
  }

  return (
    <Layout>
      <Seo title="שירותים" />
      <Head title="שירותים" img={bgImgHeader} />
      <section>
        <div className="container">
          <Root.Wrapper>
            <Root.SideNavBar>
              <ul>
                {" "}
                {services.map(serviceObj => (
                  <li
                    key={serviceObj.id}
                    className={serviceObj.id === service.id ? "selected" : ""}
                  >
                    <Link
                      to={`/services/?val=${serviceObj.id - 1}`}
                      id={serviceObj.id - 1}
                      onClick={setTheService}
                    >
                      {serviceObj.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </Root.SideNavBar>
            {service ? (
              <Root.ServiceData>
                <h1> {service.name} </h1> <p> {service.info} </p>
                <img src={bgImg} alt="" />
              </Root.ServiceData>
            ) : (
              ""
            )}
          </Root.Wrapper>
        </div>
      </section>
    </Layout>
  )
}

export default ServicesPage
